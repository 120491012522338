<template>
  <div class="modal-backdrop" v-if="this.$store.state.modal.treeCategory">
    <div class="modal-mask" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Категории</h5>
            <button type="button" class="btn-close" @click="close"></button>
          </div>
          <div class="modal-body" :key="componentKey">
            <tree
                :data="categories"
                :options="options"
                ref="tree"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Закрыть</button>
            <button type="button" class="btn btn-primary" @click="setCategories">Выбрать</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'tree-category',
  data: function(){
    return{
      componentKey: 0,
      options: {
        checkbox: true
      },
    }
  },
  computed: {
    ...mapGetters(['productCategories']),
    categories: function(){
      return Object.entries(this.productCategories).map(entry => entry[1]);
    },
  },
  mounted() {
    this.$root.$on('updateTreeCategories', () => {
      this.updateTree();
    });
  },
  beforeUpdate() {
    this.$nextTick(() => {
      this.componentKey = 1;
    });
  },
  methods: {
    setCategories: function(){
      let tree = this.$refs.tree.findAll({state: { checked: true }});
      let ids = tree.map(function(item){
                return item.id;
      }).join(",");
      this.$store.commit('updateFilterCategories', ids);
      this.close();
    },
    updateTree: function(){
      this.componentKey = 2;
    },
    close: function() {
      this.$store.state.modal.treeCategory = false;
    },
  },
};
</script>
